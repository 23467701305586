import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import { blue } from '@mui/material/colors';





import { unstable_styleFunctionSx } from '@mui/system';
const Span = styled('span')(unstable_styleFunctionSx);

export default function Testimonials() {
    return (
      <><Box sx={{ bgcolor: blue [50], py: 8, mt: 5 }}>
      <Container >
          <Typography
                  component="h2"
                  variant="h4"
                  textAlign="center"
                  sx={{
                      fontWeight: '500',
                      alignSelf: 'center',
                      mb: 5,
                      
                  
                  }}
              >
                  In den Worten unserer Kunden
              </Typography>

        </Container></Box></>
  );
}
