import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupsIcon from '@mui/icons-material/Groups';


export default function Title() {
    return (
    <><Box sx={{ bgcolor: '#0980b5', py: 8, mt: 5 }}>
        <Container >
            <Typography
                    component="h2"
                    variant="h4"
                    textAlign="center"
                    sx={{
                        fontWeight: '500',
                        alignSelf: 'center',
                        mb: 5,
                        color: 'primary.contrastText'
                        
                    
                    }}
                >
                    Top-Funktionen für Deine Fußpflegepraxis
                </Typography>
                
                <Grid container spacing={2} alignItems="stretch">
                    <Grid xs={12} md={6} lg={4} >
                        <Card sx={{height: "100%", px: 1, px: 1}}>
                            <CardContent>
                                <Typography variant="h6" sx={{mb: 2}} >
                                    <EditCalendarIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top', backgroundColor: "#ffd200", borderRadius: "5px", mr: 1}}/> 
                                    Anpassbarer Kalender
                                </Typography>
                                <Typography variant="body2">
                                Lege Öffnungszeiten und Urlaub individuell fest. Erstelle Deine Terminarten mit passender Farbe, Dauer und Preis. Lege fest, wann und was gebucht werden kann.
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6} lg={4}>
                        <Card sx={{height: "100%", px: 1}}>
                            <CardContent>

                                <Typography variant="h6" sx={{mb: 2}}>
                                    <NotificationsIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top', backgroundColor: "#ffd200", borderRadius: "5px", mr: 1}}/> 
                                    Automatische Terminerinnerung
                                </Typography>
                                <Typography variant="body2">
                                Erinnere Deine Kunden automatisch per SMS und E-Mail an Ihren Termin. So vermeidest Du Terminausfälle und Verspätungen. Die SMS sind bei uns im Preis inklusive.
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6} lg={4}>
                        <Card sx={{height: "100%", px: 1}}>
                            <CardContent>

                                <Typography variant="h6" sx={{mb: 2}}>
                                    <EventRepeatIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top', backgroundColor: "#ffd200", borderRadius: "5px", mr: 1}}/> 
                                    Private Termine im Blick
                                </Typography>
                                <Typography variant="body2">
                                Zeige Deine privaten Termine aus Deinem Smartphone-Kalender in kosmeda an. Online-Terminbuchungen werden in den Zeiträumen automatisch blockiert.
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6} lg={4}>
                        <Card sx={{height: "100%", px: 1}}>
                            <CardContent>

                                <Typography variant="h6" sx={{mb: 2}}>
                                    <EventAvailableIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top', backgroundColor: "#ffd200", borderRadius: "5px", mr: 1}}/> 
                                    Online-Terminbuchung
                                </Typography>
                                <Typography variant="body2">
                                Kunden können 24/7 Termine bei Dir online buchen. Sie benötigen kein Konto bei uns und die Buchung ist komplett kostenlos. Zusätzlich können Termine über einen Link storniert werden.
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6} lg={4}>
                        <Card sx={{height: "100%", px: 1}}>
                            <CardContent>

                                <Typography variant="h6" sx={{mb: 2}}>
                                    <CreditCardIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top', backgroundColor: "#ffd200", borderRadius: "5px", mr: 1}}/> 
                                    Kartenzahlung leicht gemacht
                                </Typography>
                                <Typography variant="body2">
                                Nutze unsere SumUp Integration, um vor Ort noch einfacher Kartenzahlungen zu empfangen. Der passende Rechnungsbetrag wird direkt an die SumUp App gesendet.
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6} lg={4}>
                        <Card sx={{height: "100%", px: 1}}>
                            <CardContent>

                                <Typography variant="h6" sx={{mb: 2}}>
                                    <GroupsIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top', backgroundColor: "#ffd200", borderRadius: "5px", mr: 1}}/> 
                                    Pflegeheime abrechnen
                                </Typography>
                                <Typography variant="body2">
                                Sende Rechnungen von mehreren Kunden an einen Empfänger. Super praktisch für z. B. Seniorenheime, bei denen jeder Kunde individuell abgerechnet wird.
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                        





                </Grid>
                <Box alignItems="center" textAlign="center" sx={{mt:4 }}>
                    <Button  variant="contained" color="primary" startIcon={<KeyboardDoubleArrowRightIcon />} sx={{backgroundColor: "white", color: "black"}} > Zur Funktionsübersicht</Button>
                </Box>
        </Container>
    </Box></>
  );
}
