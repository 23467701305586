import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { unstable_styleFunctionSx } from '@mui/system';
const Span = styled('span')(unstable_styleFunctionSx);
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Kontakt from './Kontakt';

export default function Faq() {
    return (
    <><Box sx={{ my: 5 }} >        
        
        <Typography
          component="h3"
          variant="h3"
          sx={{ fontWeight: '500',
          alignSelf: 'center',
          textAlign: 'center',}}
          
        >
          <Span>Häufige </Span>        
          <Span
          sx={{
            position: 'relative',
            "&::after": {
              content: "''",
              backgroundColor: "#ffd200",
              position: "absolute",
              bottom: "2px",
              left: "0",
              width: "100%",
               height: "6px",
              borderRadius: "5px",
              zIndex: "-1"
            }
          }}>Fragen</Span>
          
        </Typography>
        
        <Grid container  xs={12} rowSpacing={2}  sx={{ my: 3 }} justifyContent="center" alignItems="center" textAlign="center"> 
                <Grid xs={12}  sm={9} md={7} textAlign="left">
                    <Accordion variant="string" >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                        >
                            <Typography component="h6" variant="h6">Was kostet kosmeda?</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            Kosmeda kostet 9,90€ / Monat und ist monatlich kündbar. Es gibt keine Mindestvertragslaufzeit. Die App kann für 30 Tage kostenlos getestet werden - ohne Angabe von Zahlungsdaten. <br></br><br></br>Der Versand von SMS-Terminerinnerungen ist bei normaler Nutzung (300 pro Monat) inklusive.
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid xs={12}  sm={9} md={7} textAlign="left">
                    <Accordion variant="string" >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                        >
                            <Typography component="h6" variant="h6">Kann ich kosmeda auch ohne Online-Funktionen nutzen?</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                        Ja, kosmeda lässt sich natürlich auch ohne Online-Profil und ohne Online-Terminbuchung nutzen. Wir bieten einen super Kalender mit Kundenkartei und Rechnungsfunktion an, die sich alle ohne Online-Funktionen nutzen lassen. Bitte beachte, dass unsere App jedoch eine Internetverbindung benötigt.
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid xs={12}  sm={9} md={7} textAlign="left">
                    <Accordion variant="string" >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                        >
                            <Typography component="h6" variant="h6">Kann ich kosmeda kostenlos ausprobieren?</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                        Kosmeda kann für 30 Tage kostenlos und ohne Probeabo oder ähnliches ausprobiert werden. Es müssen auch keine Zahlungsdaten hinterlegt werden. Klicke dazu auf Registrieren und lege gleich los!
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid xs={12}  sm={9} md={7} textAlign="left">
                    <Accordion variant="string" >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                        >
                            <Typography component="h6" variant="h6">Ist kosmeda für Kleinunternehmer geeignet?</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                        Kosmeda ist für die Nutzung als Kleinunternehmer ausgelegt. Rechnungen können mit Hinweis auf die Kleinunternehmerregelung und ohne Steuer ausgestellt werden.
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid xs={12}  sm={9} md={7} textAlign="left">
                    <Accordion variant="string" >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                        >
                            <Typography component="h6" variant="h6">Kann ich kosmeda in Österreich oder der Schweiz nutzen?</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                        Die App kann natürlich auch in Österreich und in der Schweiz genutzt werden. Bitte beachte, dass wir keine zugelassene Registrierkasse in Österreich sind. Solltest Du eine solche benötigen, kannst Du uns auf die HelloCash Schnittstelle ansprechen.
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid xs={12}  sm={9} md={7} textAlign="left">
                    <Accordion variant="string" >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                        >
                            <Typography component="h6" variant="h6">Funktioniert die App auf allen meinen Geräten?</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                        kosmeda gibt es als App für iOS und Android, sowohl für Smartphones als auch für Tablets. Am PC kann kosmeda über die Webseite problemlos genutzt werden, wir empfehlen hierzu Chrome oder Safari.
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid xs={12}  sm={9} md={7} sx={{ my: 2, p: 2, border:"1px", borderStyle: "solid", borderColor: grey [500], borderRadius: "5px" }}>
                    <Box >
                        <Typography
                            component="h5"
                            variant="h5"
                            sx={{ fontWeight: '500', mb: 2 }}

                        >Noch fragen?</Typography>

                    </Box>
                    <Box>
                        <Typography
                            component="subtitle1"
                            sx={{ fontWeight: '300', color:"grey", }}

                        >Kontaktiere uns gerne, wenn Du noch offene Fragen hast.</Typography>
                
                    </Box>
                    <Box sx={{mt: 2}}>
                        
                    <Kontakt />
                    </Box>
                </Grid>

        </Grid>
        </Box>
        </>
        );
    }