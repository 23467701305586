import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import Paper from '@mui/material/Paper';

import Screenshot from'./Screenshot.png';





export default function Features() {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Typography
                    component="h2"
                    variant="h4"
                    textAlign={{xs: "left", md: "center"}}
                    sx={{
                        fontWeight: '500',
                        alignSelf: 'center',
                        mb: 5
                    }}
                >
                    So optimierst Du Deinen Arbeitsalltag mit kosmeda
                </Typography>
                <Grid container spacing={2} alignItems="center" direction={{xs: "row-reverse", md: "row"}} sx={{ mb: 5 }}>                    
                    <Grid container xs={12} md={6} >
                        <Grid xs={12}>
                            <Typography
                                component="h3"
                                variant="h4"
                                sx={{
                                    fontWeight: '500',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Schaffe einfache Abläufe für Dich und Deine Kunden
                            </Typography>
                        </Grid>
                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11} >
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Dank digitaler Terminverwaltung mit Online-Terminbuchung reduzierst Du das Anrufvolumen
                            </Typography>
                        </Grid>

                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11}>
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Automatische Terminerinnerungen per E-Mail und SMS vermeiden Ausfälle
                            </Typography>
                        </Grid>
                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11} >
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Kunden benötigen kein extra Konto, um den Service zu nutzen
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={6}  alignItems="center" textAlign="center" sx={{ mt: 1 }}>
                    <Paper elevation={1}  component="img"
                        sx={{                        
                            alignSelf: 'center',
                            textAlign: 'center',
                            height: "auto",
                            maxHeight: "400px",
                            borderRadius:"5px"
                        }}
                        alt="Three Smartphones"
                        src={Screenshot} >
                        
                    </Paper>                   
                    </Grid>
                </Grid>


                <Grid container spacing={2} alignItems="center" direction={{xs: "row", md: "row-reverse"}}  sx={{ mb: 5 }}>                    
                    <Grid container xs={12} md={6} >
                        <Grid xs={12}>
                            <Typography
                                component="h3"
                                variant="h4"
                                sx={{
                                    fontWeight: '500',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                               Behalte Deine Kunden im Blick
                            </Typography>
                        </Grid>
                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11} >
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Praktische Dokumentation und Anamnese für jeden Kunden
                            </Typography>
                        </Grid>

                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11}>
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Die gesamte Kundenkartei immer zur Hand, mit unserer App auch am Handy und Tablet
                            </Typography>
                        </Grid>
                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11} >
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Terminkärtchen, Fotoupload, Zuverlässigkeit und vieles mehr
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={6}  alignItems="center" textAlign="center"  sx={{ mt: 1 }}>
                    <Paper elevation={1}  component="img"
                        sx={{                        
                            alignSelf: 'center',
                            textAlign: 'center',
                            height: "auto",
                            maxHeight: "400px",
                            borderRadius:"5px"
                        }}
                        alt="Three Smartphones"
                        src={Screenshot} >
                        
                    </Paper>                   
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center" direction={{xs: "row-reverse", md: "row"}}  sx={{ mb: 5 }}>                    
                    <Grid container xs={12} md={6} >
                        <Grid xs={12}>
                            <Typography
                                component="h3"
                                variant="h4"
                                sx={{
                                    fontWeight: '500',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Erstelle Rechnungen und sammle Ausgaben
                            </Typography>
                        </Grid>
                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11} >
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                                Erstelle Rechnungen direkt aus dem Termin heraus und versende sie per E-Mail. Ein praktischer Ersatz für Quittungsblöcke
                            </Typography>
                        </Grid>

                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11}>
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                               Erfasse Deine Ausgaben und teile sie auf Wunsch mit dem Steuerberater
                            </Typography>
                        </Grid>
                        <Grid xs={1} md={1} textAlign="left">
                            <CheckIcon />
                        </Grid>
                        <Grid xs={11} md={11} >
                            <Typography
                                component="body1"
                                sx={{
                                    fontWeight: '300',
                                    alignSelf: 'center',
                                    textAlign: 'left',
                                }}
                            >
                               Anbindung an eine TSE Kasse mit HelloCash möglich
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={6}  alignItems="center" textAlign="center" sx={{ mt: 1 }}>
                    <Paper elevation={1}  component="img"
                        sx={{                        
                            alignSelf: 'center',
                            textAlign: 'center',
                            height: "auto",
                            maxHeight: "400px",
                            borderRadius:"5px"
                        }}
                        alt="Three Smartphones"
                        src={Screenshot} >
                        
                    </Paper>                    
                    </Grid>
                </Grid>

            </Box></>
  );
}
