import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { blue } from '@mui/material/colors';


import { unstable_styleFunctionSx } from '@mui/system';
const Span = styled('span')(unstable_styleFunctionSx);

export default function Unique() {
    return (
    <><Container sx={{ my: 5 }}>        
        
        <Typography
          component="h3"
          variant="h3"
          sx={{ fontWeight: '500',
          alignSelf: 'center',
          textAlign: 'center',}}
          
        >
          Was macht kosmeda
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          sx={{ fontWeight: '500',           
          alignSelf: 'center',
          textAlign: 'center',
          mb: 5, }}
        >
          <Span
          sx={{
            position: 'relative',
            "&::after": {
              content: "''",
              backgroundColor: "#ffd200",
              position: "absolute",
              bottom: "2px",
              left: "0",
              width: "100%",
               height: "6px",
              borderRadius: "5px",
              zIndex: "-1"
            }
          }}>besonders?</Span>
          
        </Typography>
        <Grid container spacing={8} sx={{ mt: 2 }}> 
          <Grid  xs={12} md={4}  sx={{ height:"100%"}}>
            <Grid container xs={12} justifyContent="center" alignItems="center" textAlign="center">
            <Avatar sx={{ bgcolor: blue [50], width: 70, height: 70 }}>
                <DirectionsCarIcon fontSize="large" sx={{ color: blue [500] }}/>
            </Avatar>
            </Grid>
            <Grid xs={12}>
          <Typography
          component="h6"
          variant="h6"
          textAlign="center"
          sx={{
              fontWeight: '500',
              alignSelf: 'center',
              my: 2,
              }}>
                Mobile Fußpfleger
          </Typography>
          </Grid>
            <Grid xs={12} textAlign="center">
                <Typography
                component="body1"
                variant="body1"
                sx={{
                    fontWeight: '300',
                    my: 2,
                    }}>
                        Über die Kundenkartei wird die passende Adresse direkt im Navi eingegeben, es gibt ein Fahrtenbuch um die Kilometer für Reisekosten zu erfassen und das Online-Profil ermöglicht auch ein mobiles Angebot.
                </Typography>
          </Grid>
          </Grid>
          <Grid  xs={12} md={4}  sx={{ height:"100%"}}>
            <Grid container xs={12} justifyContent="center" alignItems="center" textAlign="center">
            <Avatar sx={{ bgcolor: blue [50], width: 70, height: 70 }}>
                <NoteAltIcon fontSize="large" sx={{ color: blue [500] }}/>
            </Avatar>
            </Grid>
            <Grid xs={12}>
          <Typography
          component="h6"
          variant="h6"
          textAlign="center"
          sx={{
              fontWeight: '500',
              alignSelf: 'center',
              my: 2,
              }}>
                Dokumentation
          </Typography>
          </Grid>
            <Grid xs={12} textAlign="center">
                <Typography
                component="body1"
                variant="body1"
                sx={{
                    fontWeight: '300',
                    my: 2,
                    }}>
                        Im Gegensatz zu vielen reinen Terminbuchungsseiten hat kosmeda eine funktionsreiche Kundenkartei. Mit den Analysekarten kann z. B. eine Anamnese durchgeführt werden, Termine können über die Dokumentation verschriftlicht werden. 
                </Typography>
          </Grid>
          </Grid>
          <Grid  xs={12} md={4}  sx={{ height:"100%"}}>
            <Grid container xs={12} justifyContent="center" alignItems="center" textAlign="center">
            <Avatar sx={{ bgcolor: blue [50], width: 70, height: 70 }}>
                <SmartphoneIcon fontSize="large" sx={{ color: blue [500] }}/>
            </Avatar>
            </Grid>
            <Grid xs={12}>
          <Typography
          component="h6"
          variant="h6"
          textAlign="center"
          sx={{
              fontWeight: '500',
              alignSelf: 'center',
              my: 2,
              }}>
                Eigene App
          </Typography>
          </Grid>
            <Grid xs={12} textAlign="center">
                <Typography
                component="body1"
                variant="body1"
                sx={{
                    fontWeight: '300',
                    my: 2,
                    }}>
                        Kosmeda gibt es für das Smartphone und Tablet als richtige App im App Store und Google Play Store. Es muss keine Webseite mit Lesezeichen verwendet werden und die App interagiert direkt mit dem Gerät.
                </Typography>
          </Grid>
          </Grid>
         
          


        </Grid>

</Container></>
);
}