import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

import { unstable_styleFunctionSx } from '@mui/system';
const Span = styled('span')(unstable_styleFunctionSx);




const darkLogos = [
  'https://kosmeda.de/assets/rz_logo-0e5JahxW.svg',
  'https://kosmeda.de/assets/ds_logo-d4rE6j8O.svg',
  'https://kosmeda.de/assets/beautyforum_logo-H37hKtC7.webp',
  'https://lukas.zervudakis.de/assets/img/Unbenannt.webp',
];

const logoStyle = {
  width: '150px',
  height: "auto",
  margin: '10px 32px',
  opacity: 1,
};

export default function LogoCollection() {


  return (
    <Box id="logoCollection" sx={{ mt: 5}} >
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        Aus den Medien
      </Typography>
      <Grid container alignItems="center" justifyContent="center" aligntContent="center" sx={{ mt: 0.5, opacity: 1 }}>
        {darkLogos.map((logo, index) => (
          <Grid item key={index}  >
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}