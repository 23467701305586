import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Title from './components/Title';
import Features from './components/Features';
import Functions from './components/Functions';
import Trust from './components/Trust';
import LogoCollection from './components/LogoCollection';
import Unique from './components/Unique';
import Faq from './components/FAQ';
import Testimonials from './components/Testimonials';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Jost',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App() {
  return (
    <>
    <Container>
      
    <Title />
    <Features />
    </Container>
    <Functions />
    
    <Container>
    <Trust />
    </Container>
    <Testimonials />
    <Container>
    <LogoCollection />
    <Unique />
    <Faq />
      
    </Container>
    </>
  );
}
