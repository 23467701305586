import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckIcon from '@mui/icons-material/Check';





import { unstable_styleFunctionSx } from '@mui/system';
const Span = styled('span')(unstable_styleFunctionSx);

export default function Trust() {
    return (
    <><Container sx={{ my: 5 }}>        
        <Typography
          component="h3"
          variant="h3"
          sx={{ fontWeight: '500',           
          alignSelf: 'center',
          textAlign: 'center',
          mb: 5, }}
        >
          <Span
          sx={{
            position: 'relative',
            "&::after": {
              content: "''",
              backgroundColor: "#ffd200",
              position: "absolute",
              bottom: "2px",
              left: "0",
              width: "100%",
               height: "6px",
              borderRadius: "5px",
              zIndex: "-1"
            }
          }}>Vertrauen</Span>
          <Typography
          component="h3"
          variant="h3"
          sx={{ fontWeight: '500',
          alignSelf: 'center',
          textAlign: 'center',}}
          
        >
          steht bei uns an erster Stelle
        </Typography>
        </Typography>

        <Grid container spacing={3}  alignItems="stretch">
                    <Grid xs={12} md={4} >
                        <Card variant="outlined" sx={{border:2, borderColor: 'grey.500', height: "100%"}}>
                            <CardContent sx={{color:"#45486b" }}>
                                 <Typography variant="body1" fontWeight={500} sx={{mb: 1}}>
                                    Service
                                </Typography>

                                <Grid container>
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                            }}
                                        >
                                            Wir sind per Telefon, Mail und WhatsApp erreichbar
                                        </Typography>
                                    </Grid>
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                            }}
                                        >
                                            Kompetente Hilfe rund um die App und darüber hinaus
                                        </Typography>
                                    </Grid>
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                
                                            }}
                                        >
                                            Kostenloser Import von Kundendaten aus z. B. CSV Tabellen
                                        </Typography>
                                    </Grid>

                                </Grid>
                            
                            
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={4} >
                        <Card variant="outlined" sx={{border:2, borderColor: 'grey.500', height: "100%"}}>
                            <CardContent sx={{color:"#45486b" }}>
                                 <Typography variant="body1" fontWeight={500} sx={{mb: 1}}>
                                 Datenschutz
                                </Typography>

                                <Grid container>
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                            }}
                                        >
                                            Die Kundendaten werden auf deutschen Servern gespeichert
                                        </Typography>
                                    </Grid>
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                            }}
                                        >
                                            Nutzung von aktuellen Verschlüsselungsstandards
                                        </Typography>
                                    </Grid>
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                
                                            }}
                                        >
                                            Regelmäßige Backups der Daten
                                        </Typography>
                                    </Grid>

                                </Grid>
                            
                            
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={4} >
                        <Card variant="outlined" sx={{border:2, borderColor: 'grey.500', height: "100%"}}>
                            <CardContent sx={{color:"#45486b" }}>
                                 <Typography variant="body1" fontWeight={500} sx={{mb: 1}}>
                                 Keine versteckten Kosten
                                </Typography>

                                <Grid container >
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                            }}
                                        >
                                            9,90€ / Monat, monatlich kündbar und keine Mindestvertragslaufzeit
                                        </Typography>
                                    </Grid>
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                            }}
                                        >
                                            Keine Gebühren für Service, Zahlungen oder Buchungen
                                        </Typography>
                                    </Grid>
                                    <Grid xs={1} textAlign="left" sx={{mr: 1}}>
                                        <CheckIcon />
                                    </Grid>
                                    <Grid xs={10} >
                                        <Typography
                                            component="body1"
                                            sx={{
                                                fontWeight: '300',
                                                alignSelf: 'center',
                                                textAlign: 'left',
                                
                                            }}
                                        >
                                            Testphase komplett kostenlos, ohne Eingabe von Zahlungsdaten
                                        </Typography>
                                    </Grid>

                                </Grid>
                            
                            
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


        </Container></>
  );
}
