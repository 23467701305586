import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import StarIcon from '@mui/icons-material/Star';

import Titleimage from'./Three.webp';


import { unstable_styleFunctionSx } from '@mui/system';
const Span = styled('span')(unstable_styleFunctionSx);

export default function Title() {
    return (
    <><Container sx={{ my: 5 }}>
        <Typography
          component="h1"
          variant="h3"
          sx={{ fontWeight: '500',
          alignSelf: 'center',
          textAlign: 'center',}}
          
        >
          Kunden, Termine und Finanzen - für
        </Typography>
        <Typography
          component="h1"
          variant="h3"
          sx={{ fontWeight: '500',           
          alignSelf: 'center',
          textAlign: 'center',
          mb: 5, }}
        >
          <Span
          sx={{
            position: 'relative',
            "&::after": {
              content: "''",
              backgroundColor: "#ffd200",
              position: "absolute",
              bottom: "2px",
              left: "0",
              width: "100%",
               height: "6px",
              borderRadius: "5px",
              zIndex: "-1"
            }
          }}>Fußpfleger</Span>
        </Typography>
      <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} columnSpacing={5} alignItems="center" textAlign="center" >
                  <Grid xs={12} md={6} >
                  <Box
                    component="img"
                    sx={{                        
                        alignSelf: 'center',
                        textAlign: 'center',
                        height: "auto",
                        width: "100%",
                        maxHeight: "800",
                        maxWidth: "800",
                    }}
                    alt="Three Smartphones"
                    src={Titleimage}
                    />
                    
                        
                    
                    <Typography
                        variant="subtitle2" color="text.primary" alignItems="center"  fontWeight={300} sx={{ mb: 2,  }}
                        ><StarIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top' }}/><StarIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top' }}/><StarIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top' }}/><StarIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top' }}/><StarIcon sx={{ fontSize: 'inherit', verticalAlign: 'text-top' }}/>
                        4,9 Sterne im App Store
                    </Typography> 
                   
                  </Grid>
                  <Grid xs={12} md={6}  >
                    <Typography
                        variant="h5" color="text.secondary" fontWeight={300} sx={{ mb: 2, }}
                        >
                        
                        Kosmeda ist die praktische App für selbstständige Fußpfleger. Am Smartphone, Tablet oder PC.
                    </Typography>                    
                    <Button variant="outlined" size="large">Jetzt 30 Tage kostenlos testen!</Button>
                  </Grid>
                  </Grid>
                  
          </Box></Container></>
  );
}
