import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Kontakt() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment >
      <Button variant="outlined" onClick={handleClickOpen}>
        Kontakt
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth="true"
        maxWidth="xs"
        
      >
        <DialogTitle textAlign="center">{"Wir freuen uns über Fragen, Anmerkungen und Feedback"}</DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-slide-description" sx={{my: 2}} textAlign="center" >
           <Button variant="contained" size="large" fullWidth="true">E-Mail </Button> 
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description" sx={{my: 2}} textAlign="center">
           <Button variant="contained" size="large" fullWidth="true">Telefon</Button> 
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description" sx={{my: 2}} textAlign="center">
           <Button variant="contained" size="large" fullWidth="true">WhatsApp </Button>            
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
